/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

//** Begin Styles **/

// Pages
import "../assets/styles/blog.css"

// Components
import "../assets/styles/music.scss"
import "../assets/styles/components/header.scss"
import "../assets/styles/components/media-player.scss"
import "../assets/styles/components/spring-carousel.css"
import "../assets/styles/footer.css"
import "../assets/styles/sr-only.scss"

// Global
import "../assets/styles/bootstrap-override.scss"

//** End Styles */

import SEO from "./Seo"

const OuterLayout = ({ children, ...props }) => {
  return (
    <div>
      <SEO {...props} />
      {children}
    </div>
  )
}

OuterLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  header: PropTypes.bool,
  metaImage: PropTypes.string,
  metaDescription: PropTypes.string,
  contentPath: PropTypes.string,
}

export default OuterLayout
